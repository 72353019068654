.nutral {
  height: 100vh;
  padding-left: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  justify-content: center;
  z-index: 9999;
}

.background-section {
  background-color: black;
  width: 100%;
  position: relative;
  background-image: url("../../shared/icons/Landing\ page/image.png");
  background-repeat: repeat;
}

.kuku {
  mask-image: 
    linear-gradient(to top, transparent, black 10%),    /* Верх */
    linear-gradient(to bottom, transparent, black 10%), /* Низ */
    linear-gradient(to right, transparent, black 10%),  /* Лево */
    linear-gradient(to left, transparent, black 10%);   /* Право */
  mask-composite: intersect;
  -webkit-mask-composite: intersect; 
}

.info-wrap {
  border-left: 2px white solid;
  padding-left: 30px;
}

.info-wrap > h1 {
  font-size: 140px;
  color: white;
  /* line-height: 10px; */
}

.info-wrap > p {
  font-family: "Geologica", sans-serif;
  font-size: 30px;
  font-weight: 300;
  color: white;
}

.buttons {
  display: flex;
  flex-direction: row;
  margin: 0 auto;
  gap: 50px;
  margin-top: 30px;
}

.buttons > button {
  height: 60px;
  width: 250px;
  border-radius: 20px;
  font-family: "Geologica", sans-serif;
  font-size: 30px;
  font-weight: 500;
}

button:hover {
  cursor: pointer;
}

.buttons button:nth-child(1) {
  background-color: #cccccc;
}

.buttons button:nth-child(2) {
  background-color: #848484;
  color: white;
}

.about {
  width: 100%;
  display: flex;
  justify-content: end;
  /* background-color: black; */
  padding-top: 10px;
  padding-bottom: 10px;
}

.inner {
  padding: 20px;
  width: 93%;
  background-color: #151515;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px; 
}

.bold {
  font-weight: 600;
}

.inner > h2 {
  width: 70%;
  border-bottom: 1px white solid;
  font-size: 40px;
  color: white;
  font-weight: 600;
}

.text-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
}

.list {
  display: flex;
  gap: 20px;
  align-items: center;
}

.icons {
  color: white;
  font-size: 20px;
  font-weight: 200;
  font-family: "Geologica", sans-serif;
}

.wrapp {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
  font-weight: 200;
  font-family: "Geologica", sans-serif;
}

.swipers {
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  padding: 100px;
}

.slide-content {
  width: 100%;
  height: 90vh;
  border-radius: 20px;
  background-size: cover;
  position: relative;
}

#one {
  background-image: url("../../shared/images/Rectangle.png");
  background-size: cover;
}

#two {
  background-image: url("../../shared/images/swiper/htcn.png");
  background-size: cover;
}
#three {
  background-image: url("../../shared/images/swiper/Rectangle\ \(1\).png");
  background-size: cover;
}
#four {
  background-image: url("../../shared/images/swiper/Vmake-1729059017.jpg");
  background-size: cover;
}
#five {
  background-image: url("../../shared/images/swiper/щашсу.png");
  background-size: cover;
}
#six {
  background-image: url("../../shared/images/swiper/Turbotext\ AI\ Image\ 4068954.png");
  background-size: cover;
}

#lox {
  margin-top: 50px;
}

@media (max-width: 900px) {
  .info-wrap > p {
    font-size: 24px;
    margin-top: 5px;
  }

  .info-wrap > h1 {
    font-size: 100px;
    line-height: 85px;
  }
  .list {
    flex-direction: column;
    align-items: flex-start;
  }
  .inner {
    border-top-right-radius: 20px;
    border-bottom-right-radius: 20px;
    width: 95%;
  }
  .about {
    justify-content: center;
  }
  .nutral {
    padding-left: 20px;
    padding-right: 20px;
  }
  .info-wrap {
    padding-left: 15px;
  }
  .inner > h2 {
    width: 100%;
    text-align: center;
    font-size: 30px;
  }
  .wrapp {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 0px;
    font-size: 18px;
    font-weight: 300;
  }

  .wrapp > svg {
    transform: scale(0.8);
  }
  .nutral {
    height: 90vh;
  }
  #lox {
    margin-top: 30px;
  }
  .swipers {
    padding: 60px;
  }
  .slide-content {
    height: 450px;
  }
}

@media (max-width: 640px) {
  .info-wrap > p {
    font-size: 18px;
    margin-top: 5px;
  }

  .info-wrap > h1 {
    font-size: 70px;
    line-height: 65px;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    gap: 20px;
    margin-top: 30px;
    width: 100%;
  }
  .buttons > button {
    height: 45px;
    width: 100%;
    border-radius: 20px;
    font-family: "Geologica", sans-serif;
    font-size: 20px;
    font-weight: 500;
  }
  .wrapp > svg {
    transform: scale(0.7);
  }
  .wrapp {
    font-size: 14px;
    gap: 7px;
  }
  .nutral {
    height: 80vh;
  }
  #lox {
    margin-top: 20px;
  }
  .inner > h2 {
    font-size: 23px;
  }
  .swipers {
    padding: 20px;
  }
  .slide-content {
    height: 380px;
  }
}

@media (max-width: 440px) {
  .info-wrap > p {
    font-size: 16px;
    color: white;
  }

  .info-wrap > h1 {
    font-size: 40px;
    color: white;
  }
  .nutral {
    padding-left: 10px;
  }
  .info-wrap {
    padding-left: 5px;
  }
  .nutral {
    height: 70vh;
  }
  .swipers {
    padding: 5px;
  }
  .slide-content {
    height: 280px;
  }
  .slide-content {
    border-radius: 10px;
  }
  .list {
    gap: 5px;
  }
}
