@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400..900;1,400..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


.main-font{
  font-family: "Playfair Display", serif;
}

.small-font{
  font-family: "Geologica", sans-serif;
}

.noselect{
  user-select: none;
}

